import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { ClientReview } from '../components/client-review';
import { CTA } from '../components/cta';

import { BackgroundTexture } from '../components/background-texture';
import { ButtonLink } from '../components/button-link';
import { ContactSection } from '../components/contact-section';
import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { useSanitySiteSettings } from '../hooks/use-sanity-site-settings';
import { InstagramWidget } from '../components/instagram-widget';
import { AboutSection } from '../components/about-section';
import { ConsultationForm } from '../components/consultation-form';

function IndexPage(): React.ReactElement {
  return (
    <>
      <SEO title="Home" />
      <Layout>
        <Hero />
        <About />
        <Services />
        <ConsultationForm />
        <ClientReview />
        <CTA text="In need of self care?" path="/contact-us/#contact" />
        <InstagramWidget />
        <ContactSection />
      </Layout>
    </>
  );
}

function Hero(): React.ReactElement {
  const { phoneNumber } = useSanitySiteSettings();
  return (
    <div className="relative hidden h-96 sm:block">
      {/* <div className="absolute md:hidden -top-24">
        <StaticImage
          quality={90}
          src="../images/hero-bg.png"
          alt=""
          width={700}
          className="flex-1 w-full object-full"
        />
      </div> */}
      <div className="absolute inset-0 flex">
        <StaticImage
          quality={90}
          src="../images/home-hero.jpeg"
          alt=""
          className="flex-1 object-cover w-full"
        />
        <div className="absolute inset-0 flex">
          <div className="flex-1 hero-bg-filter" />
        </div>
      </div>
      <div className="relative w-full py-32 px-9 md:mx-auto sm:px-6 lg:px-8 max-w-screen-2xl">
        <h1 className="text-4xl font-semibold text-center md:text-left text-custom-gradient">
          Specialising in
          <br />
          Cosmetic Tattoo <br /> & Beauty Treatments
        </h1>
      </div>
    </div>
  );
}

function About() {
  const description = (
    <div>
      <p className="pb-6 font-semibold">
        Welcome to Adore, a modern studio focused on delivering high end results
        you will love. I offer personalized treatments tailored to the unique
        needs and desires of every client.
      </p>
      <p>
        Specialising in the latest cosmetic tattoo techniques including feather
        touch, ombre, powder brow and lip blush tattoo. Wake up with makeup with
        the confidence you deserve.
      </p>
    </div>
  );
  return (
    <AboutSection
      title="Hey girl,"
      cta="Read More"
      ctaPath="/about/"
      description={description}
      image={
        <StaticImage
          quality={90}
          src="../images/home-1.jpeg"
          alt=""
          className="flex-1 rounded-full safari-hack"
        />
      }
    />
  );
}

function Services(): React.ReactElement {
  return (
    <div className="relative overflow-hidden bg-pink-lightest">
      <div className="absolute md:hidden -top-32">
        <StaticImage
          quality={90}
          src="../images/hero-bg-up.png"
          alt=""
          width={700}
          className="flex-1 object-cover w-full"
        />
      </div>
      <div className="relative w-full px-4 mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
        <div className="hidden md:block">
          <BackgroundTexture position="left" />
        </div>
        <div className="relative grid gap-4 md:gap-8 lg:grid-cols-2">
          <div className="pt-16">
            <div className="w-full space-y-8 text-center">
              <div className="max-w-xs mx-auto">
                <div className="aspect-w-1 aspect-h-1">
                  <div className="flex">
                    <StaticImage
                      quality={90}
                      src="../images/home-3.jpeg"
                      alt=""
                      className="flex-1 rounded-full safari-hack"
                    />
                  </div>
                </div>
              </div>
              <div>
                <h2 className="text-3xl font-semibold">
                  <span className="block w-40 mx-auto mb-4 md:mb-0 text-custom-gradient md:w-full">
                    Cosmetic Tattooing
                  </span>
                </h2>
                <div className="max-w-sm mx-auto prose text-left text-gray-500">
                  <p>
                    Cosmetic tattooing, also known as permanent makeup, can
                    deliver many benefits to women of all ages. The ability to
                    semi-permanently enhance your appearance, while achieving a
                    natural look not only saves on time daily but is the perfect
                    option for correcting over plucked brows or pale patchy
                    lips.
                  </p>
                </div>
              </div>
              <p className="text-center">
                <ButtonLink aria-label="Read more about Adore" to="/about/">
                  Read More
                </ButtonLink>
              </p>
            </div>
          </div>
          <div className="py-16">
            <div className="w-full space-y-8 text-center">
              <div className="max-w-xs mx-auto">
                <div className="aspect-w-1 aspect-h-1">
                  <div className="flex">
                    <StaticImage
                      quality={90}
                      src="../images/home-2.jpeg"
                      alt=""
                      className="flex-1 rounded-full safari-hack"
                    />
                  </div>
                </div>
              </div>
              <div>
                <h2 className="text-3xl font-semibold">
                  <span className="block w-48 mx-auto mb-4 md:mb-0 text-custom-gradient md:w-full">
                    Beauty Treatments
                  </span>
                </h2>
                <div className="max-w-sm mx-auto prose text-left text-gray-500">
                  <p>
                    Looking to improve or enhance your skin? We offer a range of
                    high-quality facial treatments customized to suit individual
                    needs. With treatments for fine lines, wrinkles, ageing,
                    uneven skin tone and more. We provide peels, skin needling
                    as well as a range of other beauty services to leave your
                    skin looking vibrant, healthy, and full of life.
                  </p>
                </div>
              </div>
              <p className="text-center">
                <ButtonLink aria-label="Read more about Adore" to="/about/">
                  Read More
                </ButtonLink>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndexPage;
